import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import Title from "../../components/Title";

interface Props {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        date: string;
        title: string;
      };
    };
  };
}

const ArticlePage: React.FC<Props> = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <Section>
        <Title>{frontmatter.title}</Title>
        <Content dangerouslySetInnerHTML={{ __html: html }}></Content>
      </Section>
    </Layout>
  );
};

const Content = styled.div`
  font-family: "Merriweather", serif;
  width: 75%;
  margin: auto;

  & > p {
    font-size: 20px;
    line-height: 30px;
  }
`;

export default ArticlePage;

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "M/D/YY")
        title
      }
    }
  }
`;
